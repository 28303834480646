import React from 'react';
import get from 'lodash.get';
import HeroSlider from 'components/HeroSlider';
import { injectIntl, Link } from 'gatsby-plugin-react-intl';
import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import url from 'helpers/url';
import Videos from 'blocks/Videos';
import { multilineHTML } from 'helpers/text';

import '../../fonts/fonts.css';

import {
    Spacing,
    SharedStyles,
    Container,
    VideoBlock,
    NewVideoWithDesc,
    GenericBenefitList,
    GenericProductList,
    ImageTextTile,
    ListOfImg,
    ImageGrid,
} from '../../kenra-storybook/index';

const {
    StSectionInfo,
    StSectionTitle,
    StLargeSectionTitle,
    StSectionBody,
    StSectionLink,
    VidTitle,
    StTitle,
    StInnerText,
    StSubTitle,
    StLinkMore,
} = SharedStyles;

const renderOptions = {
    renderText: text => {
        return text.split('\n').reduce((children, textSegment, index) => {
            return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
    },
};

function parseFragments(fragments, intl) {
    let fragmentDataMap = fragments?.map(section => {
        let returnData = {};
        section.forEach(fragmentData => {
            switch (fragmentData.key) {
                case 'Image':
                    returnData['img'] = fragmentData.value;
                    break;
                case 'Title':
                    returnData['title'] = fragmentData.value;
                    break;
                case 'Text':
                    returnData['text'] = fragmentData.value;
                    if (fragmentData.value !== null) {
                        returnData['text'] = fragmentData.value.replace(
                            '\\n',
                            '\n '
                        );
                    }
                    break;
                case 'ItemColor':
                    returnData['color'] = fragmentData.value;
                    break;
                case 'ProductLink':
                    if (fragmentData.value) {
                        returnData['link'] = fragmentData.value;
                        returnData['linkText'] = intl.formatMessage({
                            id: 'products-view',
                        });
                    }
                    break;
                case 'HighlightText':
                    returnData['highlightText'] = fragmentData.value;
                    break;
                default:
                    break;
            }
        });
        return {
            image: returnData.img,
            title: returnData.title,
            text: returnData.text,
            itemColor: returnData.color,
            link: returnData.link,
            linkText: returnData.linkText,
            highlightText: returnData.highlightText,
        };
    });

    return fragmentDataMap;
}

const SimplyBlondeLanding = ({ intl, page }) => {
    let section1Data = parseFragments(page.section1Data.fragments, null);
    let collectionData = parseFragments(page.collectionData.fragments, intl);
    let tutorials = page.tutorials;
    let buttonImage = get(page, 'buttonImage.localFile.publicURL');
    return (
        <>
            <Spacing removeSpaceTop>
                <HeroSlider slides={page.banner} />
            </Spacing>
            <Spacing removeSpaceTop>
                <StSectionInfo>
                    <Container>
                        <StTitle>
                            <h2>{get(page, 'section1Title')}</h2>
                        </StTitle>
                        <StInnerText
                            dangerouslySetInnerHTML={{
                                __html: get(page, 'section1Subtitle'),
                            }}
                        />
                        <StLinkMore inversionColor>
                            <Link
                                to={'/color-list?filter=simply-blonde'}
                                style={{
                                    backgroundImage: buttonImage
                                        ? `url(${buttonImage})`
                                        : 'none',
                                }}
                            >
                                {intl.formatMessage({ id: 'products-view' })}
                            </Link>
                        </StLinkMore>
                    </Container>
                </StSectionInfo>
            </Spacing>
            <Spacing
                backgroundImage={get(
                    page,
                    'backgroundImage.localFile.publicURL'
                )}
                removeSpaceBottom
            >
                <Spacing>
                    <StSectionInfo>
                        <Container fullWidth>
                            <StTitle color={page.collectionTitleColor}>
                                <h2>{get(page, 'collectionTitle')}</h2>
                            </StTitle>
                        </Container>
                    </StSectionInfo>
                    {collectionData && (
                        <GenericProductList
                            color={page.collectionTitleColor}
                            items={collectionData}
                            split5050
                            inversionColor
                            rowRevers
                            buttonImage={get(
                                page,
                                'buttonImage.localFile.publicURL'
                            )}
                            fontFamily={'Freeland'}
                        />
                    )}
                </Spacing>
                <Spacing>
                    <Container>
                        {section1Data && (
                            <GenericBenefitList items={section1Data} />
                        )}
                    </Container>
                </Spacing>
                {page.comingSoonText && (
                    <Spacing removeSpaceBottom removeSpaceTop>
                        <Container>
                            <StSectionInfo>
                                {documentToReactComponents(
                                    JSON.parse(
                                        get(page, 'comingSoonText.raw') || '{}'
                                    ),
                                    renderOptions
                                )}
                            </StSectionInfo>
                        </Container>
                    </Spacing>
                )}

                <Spacing removeSpaceBottom>
                    <Container>
                        {page.gifImages && (
                            <ImageGrid
                                items={page.gifImages?.map(gif => {
                                    return {
                                        img: get(gif, 'localFile.publicURL'),
                                    };
                                })}
                            />
                        )}
                    </Container>
                </Spacing>
            </Spacing>
            {tutorials && (
                <Spacing>
                    <section>
                        <div>
                            <Container>
                                <VidTitle>
                                    {intl.formatMessage({
                                        id: 'learn-more-text',
                                    })}
                                </VidTitle>
                            </Container>
                        </div>

                        <NewVideoWithDesc
                            text={tutorials[0].title.title}
                            text2={tutorials[1].title.title}
                            linkMoreText={intl.formatMessage({
                                id: 'video-view-all',
                            })}
                            linkMoreHref={url.videos}
                            bgImg={getPreviewUrl(youTubeId)}
                            bgImg2={getPreviewUrl(youTubeId2)}
                            videoOptions={[{ src: getEmbedUrl(youTubeId) }]}
                            videoOptions2={[{ src: getEmbedUrl(youTubeId2) }]}
                            btnBgColor={page.collectionTitleColor}
                        />
                    </section>
                </Spacing>
            )}
            <Spacing>
                <Videos
                    title={intl.formatMessage({ id: 'kenra-education-videos' })}
                    videos={page.educationVideos}
                    bgImage={get(
                        page,
                        'educationVideoImage.localFile.publicURL'
                    )}
                />
            </Spacing>
        </>
    );
};

export default injectIntl(SimplyBlondeLanding);
